<template>
  <div>
    <div class="study-section d-flex align-center">
      <v-container>
        <v-row class="ma-0">
          <v-row
            class="home-content py-16 align-center text-center text-lg-left"
          >
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <div class="section-title lexend mb-7">
                <span>Study in Germany</span>
              </div>
              <p class="lexend-white">
                Pursue your higher studies in the Germany with world's most
                prestigious colleges and universities.
              </p>
              <v-btn class="section-btn mt-3" @click="goToForm"
                >Inquiry Now</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-row class="ma-0 study-second-section">
        <v-container class="py-12">
          <v-row class="d-flex ma-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="8"
              xl="6"
              class="d-flex text-justify"
            >
              <span class="study-para">
                Germany is a country located in Central Europe, bordered by
                Denmark to the north, Poland and the Czech Republic to the east,
                Austria and Switzerland to the south, and France, Luxembourg,
                Belgium, and the Netherlands to the west. It has a population of
                approximately 83 million people and is known for its rich
                history, culture, and economy. Germany has the largest economy
                in Europe and is known for its engineering, automobile, and
                chemical industries. It is a member of the G7, G20, and the
                European Union. Germany is also known for its high quality of
                life, excellent healthcare system, and strong education sector.
                German culture is diverse and has been shaped by its long
                history, including the influence of the Holy Roman Empire, the
                Renaissance, and the Protestant Reformation. It is known for its
                music, literature, art, and architecture, including the works of
                famous German composers such as Beethoven and Bach, and the
                Bauhaus movement.
              </span>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="4"
              xl="6"
              class="text-center"
            >
              <v-img
              class="ma-auto"
                src="../../assets/images/destination/germany-city.jpg"
                height="300"
                max-width="450"
              ></v-img>
              <br />
              <span class="city-name">Germany</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-container class="py-12 px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">Why study in Germany? </span>
                <span class="study-para">
                  There are several reasons why international students choose to
                  study in the Germany:
                  <dl>
                    <dt>Quality Education</dt>
                    <dd>
                      Germany is known for its world-class education system,
                      offering a wide range of courses at all levels of study.
                    </dd>
                    <dt>Diverse and Welcoming Environment</dt>
                    <dd>
                      Germany is a diverse and multicultural country, welcoming
                      students from all over the world and providing a safe and
                      inclusive environment for all.
                    </dd>
                    <dt>Low or No Tuition Fees</dt>
                    <dd>
                      Most universities in Germany do not charge tuition fees or
                      charge very low fees, making it an affordable option for
                      international students.
                    </dd>
                    <dt>Career Opportunities</dt>
                    <dd>
                      Germany is home to a thriving economy with a high demand
                      for skilled workers, providing students with excellent
                      career opportunities after graduation.
                    </dd>
                    <dt>Central Location</dt>
                    <dd>
                      Germany is located in the heart of Europe, providing easy
                      access to other countries and cultural experiences.
                    </dd>
                  </dl>
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">
                  Choosing a Course and University
                </span>
                <span class="study-para">
                  Germany offers a wide range of courses and universities for
                  international students. To select the best course and
                  university for you, it is essential to consider several
                  factors, such as your interests, career goals, and budget. You
                  can research courses and universities online, read student
                  reviews, and speak to education agents for advice. When
                  selecting a university, it is crucial to consider the
                  university's reputation, course quality, and teaching methods.
                  German universities use a variety of teaching methods, such as
                  lectures, seminars, and practical sessions, to provide
                  students with a well-rounded education.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> The Application Process </span>
                <span class="study-para">
                  To apply for a course in Germany, you will need to submit an
                  application directly to the university. You will need to
                  provide academic transcripts, language test results, and any
                  other documents required by the university. Once your
                  application is accepted, you will receive a letter of
                  acceptance. You will need to obtain a student visa before you
                  can study in Germany.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>

          <v-row class="ma-0">
            <v-container class="px-0 mb-12">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> Student Life in the Germany </span>
                <span class="study-para">
                  Studying in Germany offers a unique student experience. German
                  universities provide excellent support services, such as
                  accommodation, career advice, and health services. Students
                  can also participate in extracurricular activities, such as
                  sports, clubs, and societies, to make new friends and explore
                  their interests. Germany has a rich cultural history, and
                  students can enjoy a range of activities outside of their
                  studies, such as visiting historical landmarks, exploring
                  museums and galleries, and experiencing traditional German
                  food and culture.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
        </v-container>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    goToForm() {
        this.$router.push('/contact-us')
  }
  }
};
</script>

<style scoped>
.study-section {
  min-height: 500px;
  background: url(../../assets/images/country/germany-background.jpg)
    center/cover no-repeat;
}
.study-second-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/aeroplane.gif);
}
</style>

<template>
  <div>
    <div class="study-section d-flex align-center">
      <v-container>
        <v-row class="ma-0">
          <v-row
            class="home-content py-16 align-center text-center text-lg-left"
          >
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <div class="section-title lexend mb-7">
                <span>Study in UK</span>
              </div>
              <p class="lexend-white">
                Pursue your higher studies in the United Kingdom with world's
                most prestigious colleges and universities.
              </p>
              <v-btn class="section-btn mt-3" @click="goToForm"
                >Inquiry Now</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-row class="ma-0 study-second-section">
        <v-container class="py-12">
          <v-row class="d-flex ma-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="8"
              xl="6"
              class="d-flex text-justify"
            >
              <span class="study-para">
                The United Kingdom, also known as the UK, is a country located
                in northwestern Europe. It is made up of four countries:
                England, Scotland, Wales, and Northern Ireland. The UK has a
                rich history and is known for its culture, literature, music,
                and art. The UK is a highly developed country and is the
                fifth-largest economy in the world. It is a member of the G7,
                G20, and the European Union. The country is also known for its
                excellent education system and world-renowned universities. The
                UK has a diverse culture with influences from its colonial
                history and its indigenous peoples. It is known for its iconic
                landmarks such as Big Ben, Buckingham Palace, and Stonehenge. It
                is also famous for its literature, including the works of
                William Shakespeare, Charles Dickens, and Jane Austen. The UK is
                home to several world-renowned cities, including London,
                Edinburgh, and Manchester. These cities offer a variety of
                attractions such as museums, art galleries, theaters, and music
                venues. The countryside of the UK is also stunning, with
                national parks such as the Lake District and the Scottish
                Highlands.
              </span>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="4"
              xl="6"
              class="text-center"
            >
              <v-img src="../../assets/images/country/uk2.jpg" class="ma-auto" height="300" max-width="450" ></v-img>
              <br />
              <span class="city-name">United Kingdowm</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-container class="py-12 px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">Why study in UK? </span>
                <span class="study-para">
                  There are several reasons why international students choose to
                  study in the Germany:
                  <dl>
                    <dt>Quality Education</dt>
                    <dd>
                      British universities are known for their academic
                      excellence and world-class research facilities, providing
                      students with a rigorous and challenging education.
                    </dd>
                    <dt>Multicultural Environment</dt>
                    <dd>
                      The UK is a multicultural country that welcomes students
                      from all over the world, providing a diverse and inclusive
                      environment.
                    </dd>
                    <dt>Work Opportunities</dt>
                    <dd>
                      The UK offers excellent work opportunities for
                      international students, enabling them to gain valuable
                      work experience and supplement their income.
                    </dd>
                    <dt>Cultural and Historical Significance</dt>
                    <dd>
                      The UK is steeped in history and culture, offering
                      students a unique opportunity to experience a rich and
                      varied cultural heritage.
                    </dd>
                    <dt>Global Reputation</dt>
                    <dd>
                      UK universities are globally renowned, providing students
                      with a valuable and respected qualification that is
                      recognized worldwide.
                    </dd>
                  </dl>
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">
                  Choosing a Course and University
                </span>
                <span class="study-para">
                  The UK offers a vast range of courses and universities for
                  international students. To select the best course and
                  university for you, it is essential to consider several
                  factors, such as your interests, career goals, and budget. You
                  can research courses and universities online, read student
                  reviews, and speak to education agents for advice. When
                  selecting a university, it is crucial to consider the
                  university's reputation, course quality, and teaching methods.
                  British universities use a variety of teaching methods, such
                  as lectures, seminars, and practical sessions, to provide
                  students with a well-rounded education.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> The Application Process </span>
                <span class="study-para">
                  To apply for a course in the UK, you will need to submit an
                  application directly to the university or college. You will
                  need to provide academic transcripts, language test results,
                  and any other documents required by the institution. Once your
                  application is accepted, you will receive a letter of
                  acceptance. You will need to obtain a Tier 4 student visa
                  before you can study in the UK.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>

          <v-row class="ma-0">
            <v-container class="px-0 mb-12">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> Student Life in the UK </span>
                <span class="study-para">
                  Studying in the UK offers a unique student experience. British
                  universities provide excellent support services, such as
                  accommodation, career advice, and health services. Students
                  can also participate in extracurricular activities, such as
                  sports, clubs, and societies, to make new friends and explore
                  their interests. The UK has a range of outdoor activities and
                  cultural experiences to offer students, such as visiting
                  historical landmarks, experiencing traditional British food
                  and culture, and exploring the diverse and vibrant cities.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
        </v-container>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    goToForm() {
        this.$router.push('/contact-us')
  }
  }
};
</script>

<style scoped>
.study-section {
  min-height: 500px;
  background: url(../../assets/images/country/uk-background.jpg) center/cover
    no-repeat;
}
.study-second-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/aeroplane.gif);
}
</style>

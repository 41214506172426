<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column align-center justify-center" style="height: 100vh;">

        <h1 class="text-center">Sorry, this site is temporarily unavailable.</h1>
        <h2 class="text-center">Please contact your administrator.</h2>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },

  data: () => ({}),
};
</script>
<style></style>

<template>
  <div>
    <div class="study-section d-flex align-center">
      <v-container>
        <v-row class="ma-0">
          <v-row
            class="home-content py-16 align-center text-center text-lg-left"
          >
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <div class="section-title lexend mb-7">
                <span>Study in Japan</span>
              </div>
              <p class="lexend-white">
                Pursue your higher studies in the Japan with world's most
                prestigious colleges and universities.
              </p>
              <v-btn class="section-btn mt-3" @click="goToForm"
                >Inquiry Now</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-row class="ma-0 study-second-section">
        <v-container class="py-12">
          <v-row class="d-flex ma-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="8"
              xl="6"
              class="d-flex text-justify"
            >
              <span class="study-para">
                Japan is an island nation located in East Asia, bordered by the
                Pacific Ocean, the Sea of Japan, and several neighboring
                countries. It consists of four main islands - Honshu, Hokkaido,
                Kyushu, and Shikoku - as well as numerous smaller islands. Japan
                is known for its unique culture, rich history, and cutting-edge
                technology. Japan has the world's third-largest economy and is
                known for its manufacturing, electronics, and automobile
                industries. It is also a leader in technology and innovation,
                particularly in areas such as robotics and artificial
                intelligence. Japan is a member of the G7, G20, and the United
                Nations. Japanese culture is diverse and has a long history
                dating back to ancient times. It is known for its traditional
                arts such as kabuki theater, ikebana (flower arranging), and tea
                ceremonies. Japanese cuisine is also popular worldwide and
                includes sushi, ramen, and tempura.
              </span>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="4"
              xl="6"
              class="text-center"
            >
              <v-img
              class="ma-auto"
                src="../../assets/images/destination/japan-city.jpg"
                height="300"
                max-width="450"
              ></v-img>
              <br />
              <span class="city-name">Kyoto, Japan</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-container class="py-12 px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">Why study in Japan? </span>
                <span class="study-para">
                  There are several reasons why international students choose to
                  study in the USA:
                  <dl>
                    <dt>High-Quality Education</dt>
                    <dd>
                      Japan is known for its top-quality universities and
                      educational institutions, offering a wide range of courses
                      from undergraduate to postgraduate degrees in various
                      fields.
                    </dd>
                    <dt>Technological Innovation</dt>
                    <dd>
                      Japan is known for its innovative and advanced technology
                      industries, providing students with access to cutting-edge
                      research facilities.
                    </dd>
                    <dt>Cultural Experience</dt>
                    <dd>
                      Studying in Japan offers students a chance to experience
                      Japanese culture, including its language, customs, and
                      traditions.
                    </dd>
                    <dt>Safety and Security</dt>
                    <dd>
                      Japan is known for its high levels of safety and security,
                      making it an ideal location for international students.
                    </dd>
                    <dt>Job Opportunities</dt>
                    <dd>
                      Studying in Japan can provide excellent career prospects,
                      as graduates from Japanese universities are highly valued
                      by employers around the world.
                    </dd>
                  </dl>
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">
                  Choosing a Course and University
                </span>
                <span class="study-para">
                  Japan offers a wide range of courses and universities for
                  international students. To select the best course and
                  university for you, it is essential to consider several
                  factors, such as your interests, career goals, and budget. You
                  can research courses and universities online, read student
                  reviews, and speak to education agents for advice. When
                  selecting a university, it is crucial to consider the
                  university's reputation, course quality, and teaching methods.
                  Japanese universities use a variety of teaching methods, such
                  as lectures, discussions, and practical sessions, to provide
                  students with a well-rounded education.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> The Application Process </span>
                <span class="study-para">
                  To apply for a course in Japan, you will need to submit an
                  application directly to the university. You will need to
                  provide academic transcripts, Japanese language test results,
                  and any other documents required by the university. Once your
                  application is accepted, you will receive a letter of
                  acceptance. You will need to obtain a student visa before you
                  can study in Japan.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>

          <v-row class="ma-0">
            <v-container class="px-0 mb-12">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> Student Life in the Japan </span>
                <span class="study-para">
                  Studying in Japan offers a unique student experience. Japanese
                  universities provide excellent support services, such as
                  accommodation, career advice, and health services. Students
                  can also participate in extracurricular activities, such as
                  sports, clubs, and societies, to make new friends and explore
                  their interests. Japan has a unique and fascinating culture,
                  and students can enjoy a range of activities outside of their
                  studies, such as exploring the natural beauty of the country,
                  visiting tourist attractions, and experiencing traditional
                  Japanese food and culture.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
        </v-container>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    goToForm() {
        this.$router.push('/contact-us')
  }
  }
};
</script>

<style scoped>
.study-section {
  min-height: 500px;
  background: url(../../assets/images/country/japan-background.jpg) center/cover
    no-repeat;
}
.study-second-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/aeroplane.gif);
}
</style>

<template>
  <div>
    <v-container>
      <v-row class="py-16">
        <v-col
          cols="12"
          class="ma-auto justify-center d-flex flex-column text-center align-center"
        >
          <span
            class="lexend content-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            >Our Gallery</span
          >
          <div
            class="section-title text-center mb-7"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <span>See the world through our lens</span>
          </div>
        </v-col>
        <v-row class="justify-center">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            class="d-flex justify-center align-center mb-16"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <SubmitFormVue />
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-container class="mb-10">
      <v-row class="mb-10">
        <v-col
          v-for="(image, i) in images"
          :key="i"
          cols="12"
          xs="12"
          sm="12"
          md="4"
          xl="3"
          lg="3"
          class="justify-center"
          data-aos="fade-up"
        >
          <img class="image" :src="image" @click="index = i" />
          <vue-gallery-slideshow
            :images="images"
            :index="index"
            @close="index = null"
          ></vue-gallery-slideshow>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row class="py-16">
        <v-col
          cols="12"
          class="ma-auto justify-center d-flex flex-column text-center align-center"
        >
          <div
            class="section-title text-center mb-7"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <span>Certificates of Eligibility</span>
          </div>
        </v-col>
        <v-row class="justify-center">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            class="d-flex justify-center align-center mb-16"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <SubmitFormVue />
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-container class="mb-10">
      <v-row class="mb-10">
        <v-col
          v-for="(image, i) in certificates"
          :key="i"
          cols="12"
          xs="12"
          sm="12"
          md="4"
          xl="3"
          lg="3"
          class="justify-center"
          data-aos="fade-up"
        >
          <img class="image" :src="image" />
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row class="py-16">
        <v-col
          cols="12"
          class="ma-auto justify-center d-flex flex-column text-center align-center"
        >
          <div
            class="section-title text-center mb-7"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <span>Our Advertisments</span>
          </div>
        </v-col>
        <v-row class="justify-center">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            class="d-flex justify-center align-center mb-16"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <SubmitFormVue />
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-container class="mb-10">
      <v-row class="mb-10">
        <v-col
          v-for="(image, i) in advertisements"
          :key="i"
          cols="12"
          xs="12"
          sm="12"
          md="4"
          xl="3"
          lg="3"
          class="justify-center"
          data-aos="fade-up"
        >
          <img class="image" :src="image" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require("../assets/images/gallery/photo50.jpeg"),
        require("../assets/images/gallery/photo58.jpeg"),
        require("../assets/images/gallery/photo59.jpeg"),
        require("../assets/images/gallery/photo51.jpeg"),
        require("../assets/images/gallery/photo55.jpeg"),
        require("../assets/images/gallery/photo52.jpeg"),
        require("../assets/images/gallery/photo57.jpeg"),

        require("../assets/images/gallery/photo1.jpeg"),
        require("../assets/images/gallery/photo2.jpeg"),
        require("../assets/images/gallery/photo3.jpeg"),
        require("../assets/images/gallery/photo4.jpeg"),
        require("../assets/images/gallery/photo5.jpeg"),
        require("../assets/images/gallery/photo6.jpeg"),
        require("../assets/images/gallery/photo8.jpeg"),
        require("../assets/images/gallery/photo9.jpeg"),
        require("../assets/images/gallery/photo10.jpeg"),
        require("../assets/images/gallery/photo11.jpeg"),
        require("../assets/images/gallery/photo12.jpeg"),
        require("../assets/images/gallery/photo13.jpeg"),
        require("../assets/images/gallery/photo14.jpeg"),
        require("../assets/images/gallery/photo15.jpeg"),
        require("../assets/images/gallery/photo16.jpeg"),
        require("../assets/images/gallery/photo17.jpeg"),
        require("../assets/images/gallery/photo18.jpeg"),
        require("../assets/images/gallery/photo19.jpeg"),
        require("../assets/images/gallery/photo20.jpeg"),
        require("../assets/images/gallery/photo22.jpeg"),
        require("../assets/images/gallery/photo23.jpeg"),
        require("../assets/images/gallery/photo24.jpeg"),
        require("../assets/images/gallery/photo25.jpeg"),
        require("../assets/images/gallery/photo30.jpeg"),
        require("../assets/images/gallery/photo26.jpeg"),
        require("../assets/images/gallery/photo27.jpeg"),
        require("../assets/images/gallery/photo32.jpeg"),
        require("../assets/images/gallery/photo33.jpeg"),
        require("../assets/images/gallery/photo34.jpeg"),
        require("../assets/images/gallery/photo35.jpeg"),
        require("../assets/images/gallery/photo36.jpeg"),
        require("../assets/images/gallery/photo37.jpeg"),
        require("../assets/images/gallery/photo31.jpeg"),
        require("../assets/images/gallery/photo38.jpeg"),
        require("../assets/images/gallery/photo39.jpeg"),
        require("../assets/images/gallery/photo40.jpeg"),
        require("../assets/images/gallery/photo41.jpeg"),
        require("../assets/images/gallery/photo42.jpeg"),
        require("../assets/images/gallery/photo43.jpeg"),
        require("../assets/images/gallery/photo44.jpeg"),
        require("../assets/images/gallery/photo45.jpeg"),
        require("../assets/images/gallery/photo46.jpeg"),
        require("../assets/images/gallery/photo47.jpeg"),
        require("../assets/images/gallery/photo48.jpeg"),
        require("../assets/images/gallery/photo49.jpeg"),
      ],
      certificates: [
        require("../assets/images/certificates/photo1.jpeg"),
        require("../assets/images/certificates/photo2.jpeg"),
        require("../assets/images/certificates/photo3.jpeg"),
        require("../assets/images/certificates/photo4.jpeg"),
        require("../assets/images/certificates/photo5.jpeg"),
        require("../assets/images/certificates/photo6.jpeg"),
      ],
      advertisements: [
        require("../assets/images/advertisements/photo7.jpeg"),
        require("../assets/images/advertisements/photo2.jpeg"),
        require("../assets/images/advertisements/photo4.jpeg"),
        require("../assets/images/advertisements/photo5.jpeg"),
        require("../assets/images/advertisements/photo6.jpeg"),
        require("../assets/images/advertisements/photo3.jpeg"),
        require("../assets/images/advertisements/photo9.jpeg"),
        require("../assets/images/advertisements/photo1.jpeg"),
      ],
      index: null,
    };
  },
  components: {},
};
</script>

<style scoped>
.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  cursor: pointer;
  object-fit: contain;
}
</style>

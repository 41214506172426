<template>
  <div class="our-services">
    <v-container>
      <v-row class="home-content py-16 align-center text-center text-lg-left">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="7"
          xl="4"
          class="ma-auto justify-lg-center justify-xl-start d-flex flex-column text-xl-left text-lg-center align-xl-start align-lg-center"
        >
          <span class="lexend content-title" data-aos="fade-up" data-aos-duration="1000">Our Services</span>
          <div class="section-title text-center mb-7" data-aos="fade-up" data-aos-duration="1300">
            <span >What we offer?</span>
          </div>
          <p class="section-para offer-text" data-aos="fade-up" data-aos-duration="1600">
            We offer a comprehensive range of services designed to assist our
            students in achieving their individual goals. Our team of
            experienced teachers is dedicated to helping students enhance their
            language proficiency through tailored instruction and interactive
            activities. Our services encompass tuition classes, computer
            courses, and language courses, as well as test preparation to ensure
            our students are fully prepared for their exams.
          </p>
        </v-col>
        <v-col cols="0" xs="0" sm="0" md="0" lg="0" xl="0"></v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="7">
          <v-row class="mt-xl-10 mt-sm-0 justify-center">
            <v-col
              v-for="(information, index) in informations"
              :key="index"
              cols="12"
              xs="12"
              sm="12"
              md="6"
              xl="4"
              lg="4"
              class=" "
              data-aos="fade-up" data-aos-duration="1500"
            >
              <div class="card justify-center text-left" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1800">
                <div class="card-icon">
                  <img :src="information.image" class="card-box-img" />
                </div>
                <div class="my-4">
                  <span class="lexend card-box-title">{{
                    information.title
                  }}</span
                  ><br />
                </div>
                <p class="lexend card-box-info">
                  {{ information.description }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      informations: [
        {
          image: [require("../assets/images/services/consulting.svg")],
          title: "Entrance Preparation",
          description:
            "Our entrance preparation services for institute websites offer comprehensive study materials, expert guidance, and personalized support to help students excel.",
        },
        {
          image: [require("../assets/images/services/consulting.svg")],
          title: "Language Course",
          description:
            "Our language course entrance preparation services provide students with effective study strategies, interactive resources, and experienced instructors to ensure success.",
        },
        {
          image: [require("../assets/images/services/consulting.svg")],
          title: "Computer Course",
          description:
            "Our computer course entrance preparation services equip students with essential technical skills, hands-on training, and practice tests to achieve their goals.",
        },
        {
          image: [require("../assets/images/services/consulting.svg")],
          title: "Tution Classes",
          description:
            "Our tuition class entrance preparation services offer personalized instruction, engaging study materials, and expert guidance to help students succeed in their exams.",
        },
        {
          image: [require("../assets/images/services/consulting.svg")],
          title: "Job Prepation",
          description:
            "Our job preparation services provide practical training, interview coaching, and career guidance to help candidates develop the skills and confidence needed to succeed in their job search.",
        },
        {
          image: [require("../assets/images/services/consulting.svg")],
          title: "Test Preperation",
          description:
            "Our test preparation services offer students a comprehensive approach to exam readiness, with expert instruction, practice tests, and personalized feedback to maximize their scores.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.our-services {
  min-height: 625px;
  background: #e5f8f5;
}
.card {
  max-height: 350px;
  height: 340px;
  background-color: #fff;
  padding-top: 28px;
  padding-right: 15px;
  padding-bottom: 21px;
  padding-left: 15px;
  -webkit-box-shadow: 0px 4px 10px 0px #0000001a;
  box-shadow: 0px 4px 10px 0px #0000001a;
  margin-bottom: 33px;
  border-radius: 10px;
  -webkit-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
}
.card:hover {
  background: #5a5af3;
  box-shadow: 10px 15px 30px rgb(0 0 0/18%);
  background-color: #a1315d;
  padding-top: 24px;
}
.card:hover .card-box-info {
  color: #fff;
  transition: all ease-in-out 300ms;
}
.card:hover .card-box-title {
  color: #fff;
  transition: all ease-in-out 300ms;
}
.card:hover .card-box-img {
  transition: all ease-in-out 300ms;
  filter: invert(1);
}
.card:hover .card-icon {
  border: 1px solid #fff;
}
.card-icon {
  padding: 9px;
  width: 53px;
  height: 53px;
  border-radius: 100%;
  border: 1px solid #a1315d;
  -webkit-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  -moz-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.card-box-title {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.card-box-info {
  color: rgba(0, 0, 0, 0.5);
}
.offer-text {
  display: none;
}
</style>

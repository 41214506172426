var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-main"},[_c('v-container',[_c('div',[_c('v-row',{staticClass:"justify-center d-flex flex-column text-center py-16"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"lexend content-title",attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_vm._v("Testimonials")]),_c('div',{staticClass:"section-title mb-7",attrs:{"data-aos":"fade-up","data-aos-duration":"1300"}},[_c('span',[_vm._v("What are our students saying?")])])])],1),_c('div',{staticClass:"navigation-wrapper",attrs:{"data-aos":"fade-up","data-aos-duration":"1600"}},[_c('div',{ref:"slider",staticClass:"keen-slider"},_vm._l((_vm.persons),function(person,index){return _c('div',{key:index,staticClass:"keen-slider__slide number-slide1"},[_c('v-col',{staticClass:"d-flex align-center person-card flex-column flex-lg-row",attrs:{"cols":"12","xs":"12","sm":"12","md":"8","lg":"8","xl":"8"}},[_c('v-row',{staticClass:"ma-0"},[_c('div',{staticClass:"image-fit"},[_c('div',{staticClass:"img-container"},[_c('img',{staticClass:"person-img",attrs:{"src":person.image}})]),_c('div',{staticClass:"full-colour"},[_c('div',{staticClass:"full-colour-border"},[_c('img',{staticClass:"country-img",attrs:{"src":person.country}})])])])]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"section-para"},[_vm._v(_vm._s(person.description))]),_c('span',{staticClass:"section-title person-name d-flex justify-end"},[_vm._v(_vm._s(person.name))]),_c('span',{staticClass:"person-position d-flex justify-end"},[_vm._v(_vm._s(person.position))])])],1)],1)}),0),_c('svg',{class:{
            arrow: true,
            'arrow--left': true,
            'arrow--disabled': _vm.current === 0,
          },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.slider.prev()}}},[_c('path',{attrs:{"d":"M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"}})]),(_vm.slider)?_c('svg',{class:{
            arrow: true,
            'arrow--right': true,
            'arrow--disabled':
              _vm.current === _vm.slider.track.details.slides.length - 1,
          },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.slider.next()}}},[_c('path',{attrs:{"d":"M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"}})]):_vm._e()]),(_vm.slider)?_c('div',{staticClass:"dots"},_vm._l((_vm.dotHelper),function(_slide,idx){return _c('button',{key:idx,class:{ dot: true, active: _vm.current === idx },on:{"click":function($event){return _vm.slider.moveToIdx(idx)}}})}),0):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="testimonial-main">
    <v-container>
      <div>
        <v-row class="justify-center d-flex flex-column text-center py-16">
          <v-col
          cols="12"
        >
          <span
            class="lexend content-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            >Testimonials</span
          >
          <div
            class="section-title mb-7"
            data-aos="fade-up"
            data-aos-duration="1300"
          >
            <span>What are our students saying?</span>
          </div>
        </v-col>
        </v-row>
        <div
          class="navigation-wrapper"
          data-aos="fade-up"
          data-aos-duration="1600"
        >
          <div ref="slider" class="keen-slider">
            <div
              class="keen-slider__slide number-slide1"
              v-for="(person, index) in persons"
              :key="index"
            >
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="8"
                lg="8"
                xl="8"
                class="d-flex align-center person-card flex-column flex-lg-row"
              >
                <v-row class="ma-0">
                  <div class="image-fit">
                    <div class="img-container">
                      <img :src="person.image" class="person-img" />
                    </div>
                    <div class="full-colour">
                      <div class="full-colour-border">
                        <img :src="person.country" class="country-img" />
                      </div>
                    </div>
                  </div>
                </v-row>
                <div class="d-flex flex-column">
                  <span class="section-para">{{ person.description }}</span>
                  <span class="section-title person-name d-flex justify-end">{{
                    person.name
                  }}</span>
                  <span class="person-position d-flex justify-end">{{
                    person.position
                  }}</span>
                </div>
              </v-col>
            </div>
          </div>
          <svg
            @click="slider.prev()"
            :class="{
              arrow: true,
              'arrow--left': true,
              'arrow--disabled': current === 0,
            }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
            ></path>
          </svg>
          <svg
            v-if="slider"
            @click="slider.next()"
            :class="{
              arrow: true,
              'arrow--right': true,
              'arrow--disabled':
                current === slider.track.details.slides.length - 1,
            }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
          </svg>
        </div>
        <div v-if="slider" class="dots">
          <button
            v-for="(_slide, idx) in dotHelper"
            @click="slider.moveToIdx(idx)"
            :class="{ dot: true, active: current === idx }"
            :key="idx"
          ></button>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";
export default {
  computed: {
    dotHelper() {
      return this.slider
        ? [...Array(this.slider.track.details.slides.length).keys()]
        : [];
    },
  },
  data() {
    return {
      current: 1,
      slider: null,
      persons: [
        {
          image: [require("../assets/images/testimonials/binu-thokar.jpg")],
          name: "Binu Thokar",
          country: [require("../assets/images/icons/japan-icon.png")],
          position: "Fukuoka, Japan",
          description:
            "From the very first meeting with the consultancy, I was impressed by their professionalism, knowledge and expertise in the field of study abroad. They took the time to understand my academic goals and personal interests, and provided me with valuable advice and insights on the various study options available in Japan.",
        },
        {
          image: [require("../assets/images/testimonials/raj-paudel.jpeg")],
          name: "Raj Paudel",
          country: [require("../assets/images/icons/australia-icon.png")],
          position: "Sydney, Australia",
          description:
            "I highly recommend Centrum Academy Consultancy for anyone looking to study in Australia. Their expertise and guidance made the entire process stress-free, and I am now studying at a top-tier university in Australia. Thanks to their support, I am well on my way to achieving my career goals.",
        },
        {
          image: [require("../assets/images/testimonials/kanchan.jpg")],
          name: "Kanchan Neupane",
          country: [require("../assets/images/icons/japan-icon.png")],
          position: "Fukuoka, Japan",
          description:
            "Studying in Japan has been an amazing experience, and I am grateful to the study abroad consultancy for helping to make it happen. Their guidance and support were invaluable, and I would highly recommend them to anyone considering studying abroad in Japan. Thank you again for everything!",
        },
      ],
    };
  },
  mounted() {
    this.slider = new KeenSlider(
      this.$refs.slider,
      {
        loop: true,
      },
      [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 2000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ]
    )
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
};
</script>

<style>
.testimonial-main {
  background: #e5f8f5;
}
[class^="number-slide"],
[class*=" number-slide"] {
}
.number-slide1 {
}
.keen-slider__slide {
  margin-bottom: 100px 0;
  display: flex;
  justify-content: center;
}
.image-fit {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background: #a1315d;
  position: relative;
}
.full-colour {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #a1315d;
  position: absolute;
  top: 0;
}
.full-colour-border {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #a1315d;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.country-img {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background: #a1315d;
}
.person-img {
  height: 284px;
  width: 284px;
  border-radius: 50%;
}
.img-container {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  text-align: center;
  height: 300px;
}
.person-card {
  gap: 70px;
}
.person-name {
  font-family: "Lexend", sans-serif;
  font-size: 38px !important;
  color: #a1315d;
  text-transform: capitalize;
  margin-top: 20px;
}
.person-position {
  font-size: 15px;
  font-style: italic;
  font-family: "Montserrat";
}
.navigation-wrapper {
  position: relative;
}
.dots {
  display: flex;
  padding: 64px 0;
  justify-content: center;
}
.dot {
  border: none;
  width: 25px;
  height: 5px;
  background: #c5c5c5;
  border-radius: 10px;
  margin: 0 5px;
  padding: 3px;
  cursor: pointer;
}
.dot:focus {
  outline: none;
}
.dot.active {
  background: #a1315d;
}
.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}
.arrow--left {
  left: 5px;
}
.arrow--right {
  left: auto;
  right: 5px;
}
.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
</style>

<template>
    <div>
      <div class="study-section d-flex align-center">
        <v-container>
          <v-row class="ma-0">
            <v-row
              class="home-content py-16 align-center text-center text-lg-left"
            >
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div class="section-title lexend mb-7">
                  <span>About Us</span>
                </div>
                <p class="lexend-white section-title">
                    <span>Welcome to Centrum Academy Consultancy PVT LTD</span>

                </p>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </div>
      <div>
        <v-row class="ma-0 study-second-section">
          <v-container class="py-12">
            <v-row class="d-flex ma-0">

          </v-row>

        <v-row class="ma-0">
          <v-container class="py-7 px-0">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex flex-column">
              <span class="section-title"
                >About Us
              </span>
              <span class="study-para"
                >Centrum Academy Consultancy PVT. LTD is a trusted and reputed
              education consultancy firm that is dedicated to providing expert
              guidance and support to students who aspire to pursue higher
              education in leading universities and colleges across the world.
              Our consultants are highly experienced and have a deep
              understanding of the education system, admission requirements, and
              visa regulations of various countries. We have helped hundreds of
              students realize their dreams of studying abroad and have a proven
              track record of success. We strive to maintain the highest
              standards of professionalism, ethics, and integrity in all our
              dealings with students and their families. We understand that
              choosing the right course and university can be a daunting task,
              and our team of consultants is always available to provide
              reliable information and support at every step of the way. We
              provide students with comprehensive information about courses,
              universities, scholarships, and funding options to help them make
              informed decisions. We aim to ensure that our students receive the
              best possible support to achieve their academic goals and succeed
              in their chosen careers.
            </span>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
          </v-container>
        </v-row>
        <v-row>
            <div class="team-container text-center">
    <v-row class="team-sub-container" >
        <v-row class="text-center justify-center ma-0">
            <span class="section-title">Our Specialist</span>
        </v-row>
        <v-row class="mt-10 justify-center">
      <v-col v-for="(info, index) in information" :key="index" cols="12" xs="12" sm="12" md="3" xl="3" lg="3" class="team-section"  data-aos="fade-up">
            <img :src='info.image' class="team-img"/>
        <div class="info-detail justify-center text-center">
            <span class="team-name">{{ info.name }}</span><br>
            <p class="team-position mb-0">{{ info.position }}</p>
        </div>
</v-col>
</v-row>
    </v-row>
  </div>
        </v-row>
          </v-container>
        </v-row>

      </div>
    </div>
  </template>

  <script>
  export default {
    data() {
        return {
            information:[
                {
                    image: [require("../assets/images/specialist/umesh.jpg")],
                    name:'Umesh Humagain',
                    position:' Managing Director',
                },
                {
                    image: [require("../assets/images/specialist/theorem.jpg")],
                    name:'Pradip Subedi',
                    position:'Math Teacher, +2 Level',
                },
                {
                    image: [require("../assets/images/specialist/srijesh.jpg")],
                    name:'Srijesh Chaubey ',
                    position:'Chemisty Teacher, +2 Level',
                },
                {
                    image: [require("../assets/images/specialist/santosh.jpeg")],
                    name:'Santosh Singh',
                    position:'Chemistry Teacher, +2 Level',
                },
                {
                    image: [require("../assets/images/specialist/deewakar.jpeg")],
                    name:'Deewakar Sir',
                    position:'Physics Teacher, +2 Level',
                },
                {
                    image: [require("../assets/images/specialist/manoj-neupane.jpeg")],
                    name:'Manoj Neupane ',
                    position:'Physics Teacher, +2 Level',
              },
              {
                    image: [require("../assets/images/specialist/min-rana-magar.jpeg")],
                    name:'Min Rana Magar ',
                    position:'Physics Teacher, +2 Level',
              },
              {
                    image: [require("../assets/images/specialist/vishal-lama.jpeg")],
                    name:'Vishal Lama',
                    position:'IELTS Instructor',
              },
              {
                    image: [require("../assets/images/specialist/amar-pradhan.jpeg")],
                    name:'Amar Pradhan',
                    position:'IELTS Instructor',
              },
              {
                    image: [require("../assets/images/specialist/parijaat-adhikari.jpeg")],
                    name:'Parijaat Adhikari',
                    position:'German Language Instructor',
              },
              {
                    image: [require("../assets/images/specialist/kiran-timalsina.jpeg")],
                    name:'Kiran Timalsina',
                    position:'Japanese Language Instructor',
              },
              {
                    image: [require("../assets/images/specialist/ghanashyam-shrestha.jpeg")],
                    name:'Ghanashyam Shrestha',
                    position:'Korean Language Instructor',
              },
              {
                    image: [require("../assets/images/specialist/shyam-krishna-devkota.jpeg")],
                    name:'Shyam Krishna Devkota',
                    position:'German Language Instructor',
              },
            ]
        }
    },
    components: {},
  };
  </script>

  <style scoped>
  .study-section {
    min-height: 500px;
    background: url(../assets/images/country/australia.jpg) center/cover no-repeat;
  }
  .study-second-section {
    background-position: center center;
      background-repeat: no-repeat;
  }
  .team-container {
    padding-bottom: 110px;
}
.team-sub-container {
    width:75%;
    margin:auto;
}
.image-circle {
    width:13vw;
    height:13vw;
    justify-content: center;
    display: flex;
}
.team-img {
    max-width: 150px;
    max-height: 150px;
    width:150px;
    height:150px;
    border-radius:50%;
    object-fit: cover;
}
.team-name {
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    color: #a1315d;
}
.team-info {
    font-family: 'Lexend', sans-serif;
}
.team-position {
    font-size: 12px;
    font-style: italic;
    font-family: "Montserrat";
}
.team-section {
    padding-right: 40px ;
    padding-left: 40px ;

}
@media screen and (max-width: 576px) {
.team-section {
    padding-right: 0 !important;
    padding-left: 0 !important;

}
}
  </style>

<template>
  <div>
    <OurHomeVue/>
    <AboutUsVue/>
    <OurServicesVue/>
    <OurDestinationVue/>
    <OurTestimonials/>
    <ContactForm/>
  </div>
</template>

<script>
import OurHomeVue from "@/components/OurHome.vue";
import OurServicesVue from "@/components/OurServices.vue";
import AboutUsVue from '@/components/AboutUs.vue';
import OurTestimonials from "@/components/OurTestimonials.vue";
import OurDestinationVue from '@/components/OurDestination.vue';
import ContactForm from "@/components/ContactForm.vue";
export default {
  components: {
    OurHomeVue,
    OurServicesVue,
    AboutUsVue,
    OurTestimonials,
    OurDestinationVue,
    ContactForm,
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="navigation-contact">
      <v-container
        class="navigation-contact-details text-center flex-column flex-lg-row flex-xl-row pa-0 d-flex justify-space-between align-center"
      >
        <div class="navigation-phone">
          <!-- <font-awesome-icon icon="fa-solid fa-phone" /> -->
          <div style="display:flex;">
            <span class="lexend"
            ><v-icon size="20" color="white">mdi-phone</v-icon>057 524289</span>
            <span class="lexend ml-3"><v-icon size="20" color="white">mdi-cellphone</v-icon>+977 9855031989 </span>
          </div>
          <span class="lexend">info.centrumacadamy@gmail.com</span>
        </div>
        <div class="navigation-desktop">
          <!-- <font-awesome-icon icon="fa-solid fa-phone" /> -->
          <span class="lexend"
            ><v-icon size="20" color="white">mdi-phone</v-icon> 057 524289</span> 
            <span class="lexend"
            ><v-icon size="20" color="white">mdi-cellphone</v-icon> +977 9855031989 </span>
          <span class="lexend">info.centrumacadamy@gmail.com</span>
        </div>
        <div class="social-collection mt-4 mt-lg-0">
          <div class="social-icon align-center justify-center d-flex">
            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info.centrumacadamy@gmail.com" target="_blank" class="social-media-links">
              <v-icon color="#a1315d">mdi-gmail</v-icon>
            </a>
          </div>
          <div class="social-icon align-center justify-center d-flex">
            <a href="https://www.tiktok.com/@centrum_academy12" target="_blank" class="social-media-links">
              <font-awesome-icon :icon="['fab', 'tiktok']" class="icon alt" style="color: #a1315d; font-weight: bold"/>
            </a>
          </div>
          <div class="social-icon align-center justify-center d-flex">
            <a href="https://www.facebook.com/profile.php?id=100075802015968" target="_blank" class="social-media-links"><v-icon color="#a1315d">mdi-facebook</v-icon></a>
          </div>
        </div>
      </v-container>
    </div>
    <v-row class="ma-0 navigation-list">
        <v-container class="d-flex align-center px-0 justify-space-between">
          <logo/>
      <div class="navbar d-flex justify-space-between align-center" id="big-screen-nav">
        <span class="navbar-nav lexend" @click="navigateToHome">Home</span>
        <span  class="navbar-nav lexend" @click="navigateToAboutUs">About Us</span>
        <v-menu
      transition="slide-y-transition"
      offset-y
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          class="navbar-nav lexend"
          v-bind="attrs"
          v-on="on"
        >
          Destinations
        </span>
      </template>
      <v-list>
        <v-list-item
          v-for="(destination, i) in destinations"
          :key="i"
        >
          <v-list-item-title
          :menu-props="{ bottom: true, offsetY: true }"
          class="v-menu-title" @click="navigateToDestination(destination.slug)">{{ destination.country }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
        <span  class="navbar-nav lexend" @click="navigateToGallery">Gallery</span>
        <span class="navbar-nav lexend" @click="navigateToOurServices">Our Services</span>
        <v-btn class="nav-btn " @click="navigateToOurContact">Reach Us</v-btn>
      </div>
      <div
        color="white"
        fixed
        id="second-banner"
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      </div>
        <v-navigation-drawer v-model="drawer" absolute temporary class="drawer">
        <v-list nav dense>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
            class="d-flex flex-column"
            style="gap: 15px"
          >
            <div class="d-flex align-center mt-4 mb-4">
              <div class="d-flex align-center centrum-logo" @click="navigateToHome">
      <img src="../assets/images/logos/logo2.png" height="80"/>
            <div class="d-flex flex-column ml-4 text-left"><span class="lexend logo-text" >Centrum Academy</span>
            </div>
          </div>
            </div>
            <v-btn text height="0" class=" mobile-nav"> Home </v-btn>
            <v-divider class="divider"></v-divider>
            <v-btn text height="0" class=" mobile-nav" @click="navigateToAboutUs"> About Us </v-btn>
            <v-divider class="divider"></v-divider>

          <v-menu
      transition="slide-y-transition"
      bottom
      open-on-hover
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text height="0"
          class="mobile-nav"
          v-bind="attrs"
          v-on="on"
        >
          Destinations
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(destination, i) in destinations"
          :key="i"
        >
          <v-list-item-title class="v-menu-title" @click="navigateToDestination(destination.slug)">{{ destination.country }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
            <v-divider class="divider"></v-divider>
            <v-btn text height="0" class=" mobile-nav" @click="navigateToGallery"> Gallery </v-btn>
            <v-divider class="divider"></v-divider>
            <v-btn text height="0" class=" mobile-nav" @click="navigateToOurServices"> Our Services </v-btn>
            <v-divider class="divider"></v-divider>
            <v-row class="justify-center">
            <v-btn class="nav-btn "  @click="navigateToOurContact">Contact</v-btn>

            </v-row>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
        </v-container>

    </v-row>

  </div>
</template>

<script>
import Logo from './Logo.vue';
export default {
  components: {
    Logo
  },
  data() {
    return {
      drawer:false,
      destinations:[
        {
          slug: 'study-in-usa',
          country: 'Study in USA'
        },
        {
          slug: 'study-in-australia',
          country: 'Study in Australia'
        },
        {
          slug: 'study-in-canada',
          country: 'Study in Canada'
        },
        {
          slug: 'study-in-japan',
          country: 'Study in Japan'
        },
        {
          slug: 'study-in-uk',
          country: 'Study in UK'
        },
        {
          slug: 'study-in-germany',
          country: 'Study in Germany'
        }
      ]
    }
  },
  methods: {
    navigateToDestination(slug) {
      if(slug === 'study-in-usa' ) {
        this.$router.push('/destinations/study-in-usa')
      }
      if(slug === 'study-in-canada' ) {
        this.$router.push('/destinations/study-in-canada')
      }
      if(slug === 'study-in-australia' ) {
        this.$router.push('/destinations/study-in-australia')
      }
      if(slug === 'study-in-japan' ) {
        this.$router.push('/destinations/study-in-japan')
      }
      if(slug === 'study-in-uk' ) {
        this.$router.push('/destinations/study-in-uk')
      }
      if(slug === 'study-in-germany' ) {
        this.$router.push('/destinations/study-in-germany')
      }
    },
    navigateToHome() {
      this.$router.push('/')
    },
    navigateToGallery() { 
      this.$router.push('/our-gallery')
    },
    navigateToOurServices() {
      this.$router.push('/our-services')
    },
    navigateToOurContact() {
      const routeName = this.$route.name
      if (routeName === 'HomePage') {
        this.goToForm()
      } else {
        this.$router.push('/contact-us')
      }
    },
    goToForm() {
      const element = document.getElementById("our-form");
      element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    },
    navigateToAboutUs() {
      this.$router.push('/about-us')
    }
  }
};
</script>

<style scoped>
.navigation-contact {
  min-height: 58px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #a1315d;
}
.navigation-contact-details {
  padding-right: 0;
  padding-left: 0 !important;
  margin-right: auto;
  margin-left: auto;
}
.social-collection {
  display: flex;
  gap: 8px;
}
.social-icon {
  background: white;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  transition:all .3s;
  cursor:pointer;
}
.social-icon:hover {
  transform: scale(1.1);
}
.navigation-list {
  background: white;
}

.navbar {
    gap: 5px;
}
.navbar-nav {
margin-right:30px;
margin-left:16px;
color:rgba(0, 0, 0, 0.9);
cursor:pointer;
}
.navbar-nav:hover {
  /* font-weight: 600; */
  color: #a1315d;
}
.nav-btn {
    background: #000 !important;
    color: #fff!important;
    border-radius: 30px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 18px!important;
    text-transform: none!important;
    font-family: 'Lexend', sans-serif;
    height: 35px!important;
  border:2px solid black !important;
  transition:all .2s;

}
.nav-btn:hover {
  background: #fff !important;
  color: #000 !important;


}
.drawer {
      background: #fff;
    transition: all .8s;
    position: fixed;
}
.logo-text {
  font-weight: bold;
  font-size:23px !important;
  transform: scaleY(1.3);
  color: #a1315d !important;
  line-height:1;

}
.mobile-nav {
  font-family: 'Lexend', sans-serif;
  color: #000;
  font-size: 18px;
  font-weight:500;
  width: 0;
  justify-content: flex-start;
}
.divider {
  background: #000;
  margin: 0 15px;
  margin-bottom:25px;
}
#second-banner {
  display: none;
}
#big-screen-nav {
    display: block;
}
.navigation-phone {
  display: none;
}
.navigation-desktop {
  display:flex;
  gap:30px;

}
@media screen  and (max-width: 650px) {
.navigation-phone {
  display:flex;
  flex-direction: column;
  gap:10px;
}
.navigation-desktop {
  display:none;
}
}
@media screen and (max-width: 1264px) {
  #big-screen-nav {
    display: none !important;
  }
  #second-banner {
    display: block;
  }
}
</style>

<template>
  <div>
    <div class="study-section d-flex align-center">
      <v-container>
        <v-row class="ma-0">
          <v-row
            class="home-content py-16 align-center text-center text-lg-left"
          >
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <div class="section-title lexend mb-7">
                <span>Study in USA</span>
              </div>
              <p class="lexend-white">
                Pursue your higher studies in the United States with world's
                most prestigious colleges and universities.
              </p>
              <v-btn class="section-btn mt-3" @click="goToForm"
                >Inquiry Now</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-row class="ma-0 study-second-section">
        <v-container class="py-12">
          <v-row class="d-flex ma-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="8"
              xl="6"
              class="d-flex text-justify"
            >
              <span class="study-para"
                >United States of America (USA) has always been the first choice
                amongst international students when it comes to higher studies.
                Having some of the top most universities in the world that comes
                under the Ivy league, USA focuses on investing a large part of
                their economy on the research activities that are conducted at
                such universities. The government as well as institutional
                scholarships that are generously provided by the US universities
                also make them quite popular amongst international students.
                With more than 6000 educational institutions to choose from, US
                provides a wide area of choice to cater the needs of students
                coming from various background and thus becomes quite promising
                Study Destination. Study in USA from Nepal is the dream to
                accomplish and we will help you do it.
              </span>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="4"
              xl="6"
              class="text-center"
            >
              <v-img src="../../assets/images/destination/usa-city.jpg" class="ma-auto" height="350" max-width="450" ></v-img>
              <br />
              <span class="city-name">Salt Lake City, UT</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-container class="py-12 px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">Why study in USA? </span>
                <span class="study-para">
                  There are several reasons why international students choose to
                  study in the USA:
                  <dl>
                    <dt>Quality Education</dt>
                    <dd>
                      US universities are globally recognized for their high
                      standards of education. They offer a wide range of
                      courses, from undergraduate to postgraduate degrees, in
                      various disciplines.
                    </dd>
                    <dt>Research Opportunities</dt>
                    <dd>
                      The USA has a strong research culture and provides
                      excellent opportunities for students to engage in research
                      projects and work with leading academics.
                    </dd>
                    <dt>Career Prospects</dt>
                    <dd>
                      Studying in the USA can provide excellent career
                      prospects, as graduates from US universities are highly
                      valued by employers around the world.
                    </dd>
                    <dt>Cultural Diversity</dt>
                    <dd>
                      The USA is a melting pot of cultures, with people from all
                      over the world. Studying in the USA offers students an
                      opportunity to experience a diverse and vibrant culture.
                    </dd>
                    <dt>Flexibility and Customization</dt>
                    <dd>
                      US universities offer a flexible and customizable
                      education system, where students can choose their courses
                      and design their own degree programs.
                    </dd>
                    <dt>Innovation and Entrepreneurship</dt>
                    <dd>
                      The USA is home to some of the most innovative and
                      entrepreneurial companies and universities in the world,
                      providing students with opportunities to learn from and
                      work with industry leaders.
                    </dd>
                  </dl>
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">
                  Choosing a Course and University
                </span>
                <span class="study-para">
                  The USA offers a vast range of courses and universities for
                  international students. To select the best course and
                  university for you, it is essential to consider several
                  factors, such as your interests, career goals, and budget. You
                  can research courses and universities online, read student
                  reviews, and speak to education agents for advice. When
                  selecting a university, it is crucial to consider the
                  university's reputation, course quality, and teaching methods.
                  US universities use a variety of teaching methods, such as
                  lectures, discussions, and practical sessions, to provide
                  students with a well-rounded education.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> The Application Process </span>
                <span class="study-para">
                  To apply for a course in the USA, you will need to submit an
                  application directly to the university. You will need to
                  provide academic transcripts, English language test results,
                  and any other documents required by the university. Once your
                  application is accepted, you will receive a letter of offer.
                  You will need to accept the offer and pay the tuition fees
                  before you can apply for a student visa.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>

          <v-row class="ma-0">
            <v-container class="px-0 mb-12">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> Student Life in the USA </span>
                <span class="study-para">
                  Studying in the USA offers a unique student experience. US
                  universities provide excellent support services, such as
                  accommodation, career advice, and health services. Students
                  can also participate in extracurricular activities, such as
                  sports, clubs, and societies, to make new friends and explore
                  their interests.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
        </v-container>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    goToForm() {
        this.$router.push('/contact-us')
  }
  }
};
</script>

<style scoped>
.study-section {
  min-height: 500px;
  background: url(../../assets/images/country/usa-background.jpg) center/cover no-repeat;
}
.study-second-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/aeroplane.gif);
}
</style>

import { render, staticRenderFns } from "./StudyInCanada.vue?vue&type=template&id=3839f054&scoped=true&"
import script from "./StudyInCanada.vue?vue&type=script&lang=js&"
export * from "./StudyInCanada.vue?vue&type=script&lang=js&"
import style0 from "./StudyInCanada.vue?vue&type=style&index=0&id=3839f054&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3839f054",
  null
  
)

export default component.exports
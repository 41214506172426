import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faPhone);
Vue.use(Vuetify);

export default new Vuetify({
  
});

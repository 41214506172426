import Vue from 'vue'
import App from './App.vue'
import './assets/css/main.css'
import './assets/css/mobileResponsive.css'
import vuetify from './plugins/vuetify'
import router from './router'
import VueNumber from 'vue-number-animation'
import VueObserveVisibility from 'vue-observe-visibility'
import AOS from 'aos'
import 'aos/dist/aos.css'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGallerySlideshow from 'vue-gallery-slideshow';

/* import specific icons */
import {
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";


Vue.config.productionTip = false
Vue.use(VueObserveVisibility)
Vue.use(VueNumber)
library.add(faTiktok)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-gallery-slideshow', VueGallerySlideshow)
Vue.config.productionTip = false
new Vue({
  vuetify,
  router,
  render: h => h(App),
  mounted() {
    AOS.init({
      duration: 1200,
      mirror: false,
      once: true
    }
    )
  },
}).$mount('#app')

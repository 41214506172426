<template>
    <div id="our-form">
      <v-container>
        <v-row class="py-16">
          <v-col
            cols="12"
            class="ma-auto justify-center d-flex flex-column text-center align-center"
          >
            <span class="lexend content-title" data-aos="fade-up" data-aos-duration="1000">Get in touch</span>
            <div class="section-title text-center mb-7" data-aos="fade-up" data-aos-duration="1000">
              <span>Ready to join with us?</span>
            </div>
          </v-col>
          <v-row class="justify-center">
          <v-col cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          class="d-flex justify-center align-center mb-16"
          data-aos="fade-up" data-aos-duration="1000">
         <SubmitFormVue/>
        </v-col>
      </v-row>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
    import SubmitFormVue from './SubmitForm.vue';
  export default {
    components:{SubmitFormVue},
  };
  </script>
  
  <style scoped>
  </style>
  
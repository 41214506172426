<template>
  <div>
    <div class="study-section d-flex align-center">
      <v-container>
        <v-row class="ma-0">
          <v-row
            class="home-content py-16 align-center text-center text-lg-left"
          >
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <div class="section-title lexend mb-7">
                <span>Study in Australia</span>
              </div>
              <p class="lexend-white">
                Pursue your higher studies in the Australia with world's most
                prestigious colleges and universities.
              </p>
              <v-btn class="section-btn mt-3" @click="goToForm"
                >Inquiry Now</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-row class="ma-0 study-second-section">
        <v-container class="py-12">
          <v-row class="d-flex ma-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="8"
              xl="6"
              class="d-flex text-justify"
            >
              <span class="study-para">
                Australia is a country and continent located in the southern
                hemisphere, surrounded by the Indian and Pacific Oceans. It is
                the world's sixth-largest country by land area, covering
                approximately 7.7 million square kilometers. Australia is known
                for its unique wildlife, stunning landscapes, and vibrant
                cities. Australia is a highly developed country with a diverse
                economy based on industries such as mining, agriculture,
                tourism, and services. It is a member of the G20, the United
                Nations, and the Commonwealth of Nations. Australia is also
                known for its high quality of life, excellent healthcare system,
                and strong education sector. Australian culture is diverse and
                has been shaped by its indigenous heritage, as well as its
                history as a British colony. The country is known for its
                friendly people, laid-back lifestyle, and love for outdoor
                activities such as surfing, hiking, and camping. Australian
                cuisine is also diverse and influenced by its multicultural
                population, including dishes such as meat pies, seafood, and
                barbecue.
              </span>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="4"
              xl="6"
              class="text-center "
            >
              <v-img
              class="ma-auto"
                src="../../assets/images/destination/australia-city.jpg"
                height="300"
                max-width="450"
              ></v-img>
              <br />
              <span class="city-name">Sydney, Australia</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-container class="py-12 px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">Why study in Australia? </span>
                <span class="study-para">
                  There are several reasons why international students choose to
                  study in the USA:
                  <dl>
                    <dt>Quality Education</dt>
                    <dd>
                      Australian universities are globally recognized for their
                      high standards of education. They offer a wide range of
                      courses, from undergraduate to postgraduate degrees, in
                      various disciplines.
                    </dd>
                    <dt>Research Opportunities</dt>
                    <dd>
                      Australia has a strong research culture and provides
                      excellent opportunities for students to engage in research
                      projects and work with leading academics.
                    </dd>
                    <dt>Career Prospects</dt>
                    <dd>
                      Studying in Australia can provide excellent career
                      prospects, as graduates from Australian universities are
                      highly valued by employers around the world.
                    </dd>
                    <dt>Cultural Diversity</dt>
                    <dd>
                      Australia is a multicultural country with people from all
                      over the world. Studying in Australia offers students an
                      opportunity to experience a diverse and vibrant culture.
                    </dd>
                    <dt>High Standard of Living</dt>
                    <dd>
                      Australia has a high standard of living, with excellent
                      healthcare, education, and infrastructure.
                    </dd>
                    <dt>Work and Study</dt>
                    <dd>
                      Students can work part-time while studying in Australia,
                      which can help them to support themselves financially.
                    </dd>
                  </dl>
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">
                  Choosing a Course and University
                </span>
                <span class="study-para">
                  Australia offers a vast range of courses and universities for
                  international students. To select the best course and
                  university for you, it is essential to consider several
                  factors, such as your interests, career goals, and budget. You
                  can research courses and universities online, read student
                  reviews, and speak to education agents for advice. When
                  selecting a university, it is crucial to consider the
                  university's reputation, course quality, and teaching methods.
                  Australian universities use a variety of teaching methods,
                  such as lectures, tutorials, and practical sessions, to
                  provide students with a well-rounded education.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> The Application Process </span>
                <span class="study-para">
                  To apply for a course in Australia, you will need to submit an
                  application directly to the university or through an education
                  agent. You will need to provide academic transcripts, English
                  language test results, and any other documents required by the
                  university. Once your application is accepted, you will
                  receive a letter of offer. You will need to accept the offer
                  and pay the tuition fees before you can apply for a student
                  visa.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>

          <v-row class="ma-0">
            <v-container class="px-0 mb-12">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">
                  Student Life in the Australia
                </span>
                <span class="study-para">
                  Studying in Australia offers a unique student experience.
                  Australian universities provide excellent support services,
                  such as accommodation, career advice, and health services.
                  Students can also participate in extracurricular activities,
                  such as sports, clubs, and societies, to make new friends and
                  explore their interests. Australia has a relaxed and friendly
                  culture, and students can enjoy a range of activities outside
                  of their studies, such as exploring the natural beauty of the
                  country, visiting tourist attractions, and enjoying the local
                  cuisine.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
        </v-container>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    goToForm() {
        this.$router.push('/contact-us')
  }
}
};
</script>

<style scoped>
.study-section {
  min-height: 500px;
  background: url(../../assets/images/country/australia-background.jpg)
    center/cover no-repeat;
}
.study-second-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/aeroplane.gif);
}
</style>

<template>
  <div class="contact-form">
    <v-container>
      <v-row class="mt-16">
        <v-col
          cols="12"
          data-aos="fade-up"
          data-aos-duration="1400"
          class="ma-auto justify-center d-flex flex-column text-center align-center"
        >
          <span class="lexend content-title">Contact Us</span>
          <div class="section-title text-center mb-7">
            <span>Ready to join with us?</span>
          </div>
        </v-col>
        <v-row
          class="d-flex card-container mt-13 mb-16 pb-16 ma-0 justify-center"
        >
          <v-col cols="4" class="contact-card">
            <v-col
              cols="12"
              xs="6"
              sm="6"
              md="12"
              lg="12"
              xl="12"
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <span class="footer-title">Contact Us</span>
              <v-row
                class="ma-0 mt-4 justify-center flex-column"
                style="gap: 2px"
              >
                <div class="navigation-phone">
                  <v-icon size="20" color="white">mdi-phone</v-icon>
                  <span class="lexend footer-list-item">
                    &nbsp;&nbsp;057 524289</span
                  >
                </div>
                <div class="navigation-phone">
                  <v-icon size="20" color="white">mdi-cellphone</v-icon>
                  <span class="lexend footer-list-item">
                    &nbsp;&nbsp;+977 9855031989</span
                  >
                </div>
                <div class="mt-9">
                  <span class="footer-title">Email Us</span>
                  <div class="navigation-phone mt-4">
                    <v-icon size="20" color="white">mdi-email</v-icon>
                    <span class="lexend footer-list-item">
                      &nbsp;&nbsp;info.centrumacadamy@gmail.com</span
                    >
                  </div>
                </div>
                <div class="mt-9">
                  <span class="footer-title">Visit Us</span>
                  <div class="navigation-phone mt-5">
                    <v-icon size="20" color="white"
                      >mdi-map-marker-radius</v-icon
                    >
                    <span class="lexend footer-list-item">
                      &nbsp;&nbsp;Hetauda-4, School Road</span
                    >
                  </div>
                </div>

                <div class="navigation-phone">
                  <v-icon size="20" color="white" class="non-visible"
                    >mdi-map-marker-radius</v-icon
                  >
                  <span class="lexend footer-list-item">
                    &nbsp;&nbsp;Rijal Plaza 1st floor</span
                  >
                </div>
                <div class="mt-9">
                  <span class="footer-title">Follow Us</span>
                  <div class="social-collection pt-5 mt-lg-0">
                    <div class="social-icon align-center justify-center d-flex">
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=info.centrumacadamy@gmail.com"
                        target="_blank"
                        class="social-media-links"
                      >
                        <v-icon color="#000">mdi-gmail</v-icon>
                      </a>
                    </div>
                    <div class="social-icon align-center justify-center d-flex">
                      <a
                        href="https://www.tiktok.com/@centrum_academy12"
                        target="_blank"
                        class="social-media-links"
                      >
                        <font-awesome-icon
                          :icon="['fab', 'tiktok']"
                          class="icon alt"
                          style="color: #000; font-weight: bold"
                        />
                      </a>
                    </div>
                    <div class="social-icon align-center justify-center d-flex">
                      <a
                        href="https://www.facebook.com/profile.php?id=100075802015968"
                        target="_blank"
                        class="social-media-links"
                        ><v-icon color="#000">mdi-facebook</v-icon></a
                      >
                    </div>
                  </div>
                </div>
              </v-row>
            </v-col>
            <div class="block-one"></div>
            <div class="block-two"></div>
          </v-col>
          <v-col
            cols="12"
            xs="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            class="submit-form"
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            <SubmitForm />
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubmitForm from "../components/SubmitForm.vue";
export default {
  components: {
    SubmitForm,
  },
};
</script>

<style scoped>
.contact-form {
  background: #e5f8f5;
}
.contact-card {
  background: #041e39;
  border-radius: 10px 0 0 10px;
  position: relative;
  overflow: hidden;
}
.submit-form {
  background: #fff;
  border-radius: 0px 10px 10px 0px;
}
.block-one {
  position: absolute;
  left: auto;
  top: auto;
  right: -31%;
  bottom: -15%;
  width: 300px;
  height: 300px;
  border-radius: 1000px;
  background-color: rgba(48, 135, 249, 0.5);
}
.block-two {
  position: absolute;
  left: 193px;
  top: auto;
  right: 0;
  bottom: 10%;
  z-index: 10;
  width: 100px;
  height: 100px;
  border-radius: 1000px;
  background-color: rgba(15, 191, 149, 0.55);
}
.social-collection {
  display: flex;
  gap: 18px;
}
.social-icon {
  background: white;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  transition: all 0.3s;
  cursor: pointer;
}
.social-icon:hover {
  transform: scale(1.1);
}
</style>

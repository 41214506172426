import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import StudyInUsa from '../views/destinations/StudyInUsa.vue'
import StudyInJapan from '../views/destinations/StudyInJapan.vue'
import StudyInCanada from '../views/destinations/StudyInCanada.vue'
import StudyInUk from '../views/destinations/StudyInUk.vue'
import StudyInGermany from '../views/destinations/StudyInGermany.vue'
import StudyInAustralia from '../views/destinations/StudyInAustralia.vue'
import ContactUs from '../views/ContactUs.vue'
import AboutUsDetail from '../views/AboutUsDetail.vue'
import OurServicesDetail from '../views/OurServicesDetail.vue'
import OurGallery from '../views/OurGallery.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: 'Home | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/destinations/study-in-usa',
    name: 'study-in-usa',
    component: StudyInUsa,
    meta: {
      title: 'Study in USA | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/destinations/study-in-germany',
    name: 'study-in-germany',
    component: StudyInGermany,
    meta: {
      title: 'Study in Germany | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/destinations/study-in-australia',
    name: 'study-in-australia',
    component: StudyInAustralia,
    meta: {
      title: 'Study in Australia | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/destinations/study-in-uk',
    name: 'study-in-uk',
    component: StudyInUk,
    meta: {
      title: 'Study in UK | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/destinations/study-in-japan',
    name: 'study-in-japan',
    component: StudyInJapan,
    meta: {
      title: 'Study in Japan | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/destinations/study-in-canada',
    name: 'study-in-canada',
    component: StudyInCanada,
    meta: {
      title: 'Study in Canada | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs,
    meta: {
      title: 'Contact Us | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsDetail,
    meta: {
      title: 'About Us | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/our-services',
    name: 'our-services',
    component: OurServicesDetail,
    meta: {
      title: 'Our Services | Centrum Academy Consultancy PVT LTD'
    }
  },
  {
    path: '/our-gallery',
    name: 'our-gallery',
    component: OurGallery,
    meta: {
      title: 'Our Gallery | Centrum Academy Consultancy PVT LTD'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500); // Set a timeout of 500 milliseconds to delay the scroll animation
    });
  }
})

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // Continue resolving the route
  next()
})

export default router

<template>
  <div class="services-detail">
    <v-container>
      <div class="desktop-view">
        <v-row class="text-center justify-center d-flex section-title py-16">
          <span>Our Services</span>
        </v-row>
        <v-row
          class="justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ testPreparation.title }}</span>
            <p class="section-para">
              {{ testPreparation.para }}
            </p>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="justify-center"
          >
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="nursing"></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="bridge"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ computerCourse.title }}</span>
            <p class="section-para">
              {{ computerCourse.para }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ languageCourse.title }}</span>
            <p class="section-para">
              {{ languageCourse.para }}
            </p>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="justify-center"
          >
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="language"></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="tution"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ tutionClass.title }}</span>
            <p class="section-para">
              {{ tutionClass.para }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ jobPreparation.title }}</span>
            <p class="section-para">
              {{ jobPreparation.para }}
            </p>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="justify-center"
          >
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="job"></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="my-16 pb-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="entrance"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ entrancePreparation.title }}</span>
            <p class="section-para">
              {{ entrancePreparation.para }}
            </p>
          </v-col>
        </v-row>
      </div>
      <div class="mobile-view">
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="nursing"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ testPreparation.title }}</span>
            <p class="section-para">
              {{ testPreparation.para }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="bridge"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ computerCourse.title }}</span>
            <p class="section-para">
              {{ computerCourse.para }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="language"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ languageCourse.title }}</span>
            <p class="section-para">
              {{ languageCourse.para }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="tution"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ tutionClass.title }}</span>
            <p class="section-para">
              {{ tutionClass.para }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="job"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ jobPreparation.title }}</span>
            <p class="section-para">
              {{ jobPreparation.para }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="my-16 justify-center align-center"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="">
            <div
              class="our-services-container"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2500"
            >
              <v-img :src="entrance"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            class="d-flex flex-column justify-center"
          >
            <span class="section-title">{{ entrancePreparation.title }}</span>
            <p class="section-para">
              {{ entrancePreparation.para }}
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import nursing from "../assets/images/servicesDetail/test-preparation.jpg";
import bridge from "../assets/images/servicesDetail/computer-course.jpg";
import language from "../assets/images/servicesDetail/language.jpg";
import tution from "../assets/images/servicesDetail/tution.jpg";
import job from "../assets/images/servicesDetail/job-preparation.jpg";
import entrance from "../assets/images/servicesDetail/entrance.png";

export default {
  data() {
    return {
      nursing,
      bridge,
      language,
      tution,
      job,
      entrance,
      testPreparation: {
        title: "Test Preparation",
        para: "We offer comprehensive test preparation services for a range of exams, including IELTS, TOEFL, PTE, and GMAT. Our experienced instructors use proven methods and materials to help students prepare for these exams and achieve their desired scores. We offer personalized attention and guidance to each student, identifying their strengths and weaknesses and tailoring our courses to meet their individual needs. With our extensive knowledge of these exams and our commitment to excellence, we are confident that our test preparation services will help students succeed in their exams and advance their academic or professional careers.",
      },
      computerCourse: {
        title: "Computer Course",
        para: `Our courses are designed to equip students with the knowledge and skills they need to thrive in today's digital world. We offer three different packages: Basic, Diploma, and Basic + Diploma, each of which provides a comprehensive curriculum that covers all the essential topics in computer science. Our experienced instructors use a hands-on approach, providing students with practical experience and real-world examples that help them to develop their skills and build their confidence. Whether you're a beginner or an experienced user, our computer courses will provide you with the tools you need to succeed in your personal and professional life.`,
      },
      languageCourse: {
        title: "Language Course",
        para: "We are proud to offer entrance preparation services for students looking to learn Japanese, German, English, and Korean. Our team of experienced language instructors are dedicated to providing comprehensive language training courses that cover all the necessary grammar, vocabulary, and speaking skills required to excel in these languages. We use proven teaching methods and provide personalized attention to each student, ensuring that they achieve their language learning goals.With our expertise and commitment to excellence, we are confident that our language preparation services will prepare you for success in your language exams and beyond.",
      },
      tutionClass: {
        title: "Tuition Class",
        para: `We are pleased to offer tuition classes for students at all levels, starting from 9th and 10th grade, up to 11th and 12th grade, as well as Bachelor's and Master's degree programs. Our team of experienced and qualified tutors are dedicated to providing personalized attention to each student, helping them to overcome their academic challenges and achieve their learning goals. We use a comprehensive approach to teaching, covering all the necessary topics and concepts in a structured and engaging manner. With our commitment to excellence and our passion for education, we are confident that our tuition classes will provide students with the skills and knowledge they need to succeed academically and beyond.`,
      },
      jobPreparation: {
        title: "Job Preparation",
        para: `We are proud to offer job preparation services for students interested in pursuing careers in the fields of banking, lok sewa, and sikshek ayog.With our extensive knowledge of the job market and our commitment to excellence, we are confident that our job preparation services will help students land their desired jobs and advance their careers. We provide the necessary training and support to help students succeed in competitive exams and interviews, giving them the confidence they need to excel in their chosen professions.`,
      },
      entrancePreparation: {
        title: "Entrance Preparation",
        para: `We are pleased to offer a range of entrance preparation services for students looking to pursue careers in various fields such as PCL Nursing, H.A, Pharmacy, Lab technicians, Agriculture, Vet nary, Forestry, and Engineering. Our team of experienced instructors are dedicated to helping students achieve their academic goals by providing comprehensive exam preparation courses that cover all the necessary topics and concepts. With our tailored approach and proven track record of success, we are confident that our services will help students excel in their entrance exams and gain admission to their desired programs.`,
      },
    };
  },
};
</script>

<style scoped>
.services-detail {
  background: #e5f8f5;
}
.our-services-container {
  justify-content: center;
  display: flex;
}
.our-services-container img {
  justify-content: center;
  display: flex;
  object-fit: cover;
  /* width:550px; */
  border-radius: 20px;
}
.mobile-view {
  display: none;
}
@media only screen and (max-device-width: 958px) {
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
}
</style>

<template>
  <div class="about-us">
    <v-container>
      <v-row class="ma-0">
        <v-row class="home-content py-16">
          <v-col cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="d-flex align-center justify-center"
            data-aos="fade-right" data-aos-duration="2000"
          >
            <div class="about-us-image">
            <img src="../assets/images/dashboard/aboutUsBanner.png" height="400" width="400" class="about-us-img"/>
                <div class="round-circle d-flex align-center justify-center flex-column">
                  <span class="year-date lexend">{{ new Date().getFullYear() - 2018}}+</span>
                  <span class="year-experience lexend"> year of experince</span>
                </div>
                </div>
          </v-col>
          <v-col cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="6"
          xl="6" class="justify-center d-flex text-center text-lg-left flex-column mobile-view mt-4 mt-lg-0" >
            <span class="lexend content-title"  data-aos="fade-up" data-aos-duration="1000">About Us</span>
            <div class="section-title  mb-7 d-flex flex-column"  data-aos="fade-up" data-aos-duration="1300">
              <span>Welcome to Centrum Academy</span>
              <span>Consultancy</span>

            </div>
            <p class="section-para"  data-aos="fade-up" data-aos-duration="1600">
              Centrum Academy Consultancy PVT. LTD is a trusted and reputed
              education consultancy firm that is dedicated to providing expert
              guidance and support to students who aspire to pursue higher
              education in leading universities and colleges across the world.
              Our consultants are highly experienced and have a deep
              understanding of the education system, admission requirements, and
              visa regulations of various countries. We have helped hundreds of
              students realize their dreams of studying abroad and have a proven
              track record of success. We strive to maintain the highest
              standards of professionalism, ethics, and integrity in all our
              dealings with students and their families. We understand that
              choosing the right course and university can be a daunting task,
              and our team of consultants is always available to provide
              reliable information and support at every step of the way. We
              provide students with comprehensive information about courses,
              universities, scholarships, and funding options to help them make
              informed decisions. We aim to ensure that our students receive the
              best possible support to achieve their academic goals and succeed
              in their chosen careers.
            </p>
          <v-btn
            class="section-btn mt-3"
            data-aos="fade-up"
            data-aos-duration="2000"
            @click="navigateToAboutUs"
          >
            Read More
          </v-btn>

          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    navigateToAboutUs() {
      this.$router.push('/about-us')
    }
  }
};
</script>

<style scoped>
.about-us-img {
  border-radius:50%;
  object-fit:cover;
}
.about-us-image {
  position:relative;
}
.round-circle {
  background: white;
  z-index:1;
  height:180px;
  width:180px;
  border-radius:50%;
  position: absolute;
    bottom: 60px;
    right: -60px;
    box-shadow: 0px 4px 10px 0px #00000040;
}
.round-circle::after {
  content: "";
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    z-index: initial;
    border: 2px dotted #a1315d;
    border-radius: 100%;
}
.about-us-image::after {
  content: "";
    position: absolute;
    top: -20px;
    right: -20px;
    bottom: -14px;
    left: -20px;
    z-index: initial;
    border: 2px dotted #a1315d;
    border-radius: 100%;
}
.year-date {
color: #a1315d !important;
font-weight: 800;
    font-size: 25px;
    line-height: normal;
    letter-spacing: normal;
}
.year-experience {
color:#000;
font-weight:bold;
}
</style>

<template>
  <div class="our-home">
    <v-container>
        <v-row class="home-content py-16 align-center text-center text-lg-left">
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <div class="section-title montserrat mb-7" data-aos="fade-right" data-aos-duration="1500">
              <span>Discover the pathway to success.</span>
            </div>
            <p class="section-para" data-aos="fade-left" data-aos-duration="2000">
              The journey to success is not always easy. That's why we offer
              personalized consultancy solutions to help you discover the
              pathway to success that is best suited to your unique goals.
            </p>
            <v-btn class="section-btn mt-3" @click="goToForm">Inquiry Now</v-btn>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            class="justify-center d-flex"
            style="position: relative"
          >
            <div>
              <img
              class="girl-thinking"
                src="../assets/images/dashboard/home-image.png"
                height="550"
                data-aos="fade-left" data-aos-duration="1500"
              />
            </div>
            <div style="position: absolute; top: 25px">
              <img
                src="../assets/images/dashboard/cloud.png"
                class="cloud"
                height="110"
                data-aos="zoom-in" data-aos-duration="2000" data-aos-easing="linear"
              />
            </div>
          </v-col>
      </v-row>
      <img
        src="../assets/images/dashboard/square-pattern.svg"
        height="150"
        class="square-img"
      />
      <img
        src="../assets/images/dashboard/circle-pattern.svg"
        height="250"
        class="circle-img"
      />
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
     goToForm() {
      const element = document.getElementById("our-form");
element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
  }
};
</script>

<style scoped>
.our-home {
  min-height: 706px;
  background: #e5f8f5;
  position: relative;
}

.square-img {
  position: absolute;
  top: 0;
  right: 30px;
  z-index: initial;
}
.circle-img {
  position: absolute;
  right: 20px;
  bottom: 0;
  z-index: initial;
  animation: rotate 6s infinite cubic-bezier(0.4, 0, 1, 1);
}
@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
.char {
  display: inline-block;
  white-space: pre-wrap;
}

.cloud {
  position: absolute;
  top: -72px;
}
</style>

<template>
  <div id="our-form">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="">
        <v-alert type="success" title="Email Sent" text="" v-if="isMailSent"
          >Email Sent! Thank you for submitting a form.</v-alert>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="d-flex justify-center"
      >
        <v-form
          class="form-validation"
          v-model="valid"
          lazy-validation
          ref="submitForm"
          @submit.prevent="sendEmail"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <span class="form-text">Full Name</span>
                <v-text-field
                  v-model="user.name"
                  :rules="rules.name"
                  label="Enter your full name"
                  name="name"
                  solo
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="pa-0 pr-0 pr-lg-3"
              >
                <span class="form-text">Mobile Number</span>
                <v-text-field
                  name="phone"
                  v-model="user.number"
                  :rules="rules.number"
                  label="Mobile Number"
                  solo
                  type="number"
                  outlined
                  max="10"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="pa-0 pl-0 pl-lg-3"
              >
                <span class="form-text">Email ID</span>
                <v-text-field
                  name="email"
                  label="Your Email"
                  solo
                  outlined
                  v-model="user.email"
                  :rules="rules.email"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="pa-0 pr-0 pr-lg-3"
              >
                <span class="form-text">Courses Catagory</span>
                <v-select
                  name="category"
                  outlined
                  solo
                  required
                  v-model="selectedCategories"
                  :items="categories"
                  :value="null"
                  label="Categories"
                  item-text="name"
                  item-value="name"
                  class="select-field"
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="pa-0 pl-0 pl-lg-3"
              >
                <span class="form-text">Courses</span>
                <v-select
                  outlined
                  filled
                  solo
                  name="courses"
                  v-model="selectedCourses"
                  :items="courses"
                  :value="null"
                  label="Courses"
                  :disabled="!selectedCategories"
                  item-text="name"
                  item-value="name"
                  class="select-field"
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="pa-0 pr-0 pr-lg-3"
              >
                <span class="form-text">Age</span>
                <v-text-field
                  name="age"
                  label="Your Age"
                  type="number"
                  v-model="user.age"
                  :rules="rules.age"
                  solo
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="pa-0 pl-0 pl-lg-3"
              >
                <span class="form-text">When do you plan to study?</span>
                <v-combobox
                  outlined
                  filled
                  solo
                  name="plan"
                  v-model="user.plan"
                  :rules="rules.plan"
                  :items="items"
                ></v-combobox>
              </v-col>
              <v-col cols="12 pa-0">
                <span class="form-text">Message</span>
                <v-textarea
                  name="message"
                  label="Type your message here..."
                  auto-grow
                  v-model="user.message"
                  :rules="rules.message"
                  outlined
                  solo
                  rows="9"
                  row-height="15"
                ></v-textarea>
              </v-col>
              <v-btn class="section-btn mt-2" type="submit">Submit Now</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
export default {
  data() {
    return {
      isMailSent: false,
      categories: [
        {
          id: 1,
          name: "Destination",
          courses: [
            { id: 1, name: "Study in Australia" },
            { id: 2, name: "Study in Canada" },
            { id: 3, name: "Study in Japan" },
            { id: 4, name: "Study in Germany" },
            { id: 5, name: "Study in UK" },
          ],
        },
        {
          id: 2,
          name: "Preparation",
          courses: [
            { id: 4, name: "IELTS" },
            { id: 5, name: "TOEFL" },
            { id: 6, name: "GMAT" },
            { id: 7, name: "GRE" },
          ],
        },
        {
          id: 3,
          name: "Language",
          courses: [
            { id: 9, name: "Japanese" },
            { id: 10, name: "Korean" },
            { id: 11, name: "English" },
            { id: 12, name: "Germany" },
          ],
        },
      ],
      courses: [],
      selectedCategories: null,
      selectedCourses: null,
      valid: true,
      items: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Augest",
        "September",
        "October",
        "November",
        "December",
      ],
      user: {
        name: "",
        email: "",
        number: "",
        age: "",
        destination: "",
        message: "",
        courses: "",
        plan: "",
      },
      rules: {
        name: [(v) => !!v || "Your name is required."],
        email: [
          (v) => !!v || "Your email is required.",
          (v) => this.isValidEmail(v) || "E-mail must be valid.",
        ],
        number: [
          (v) => !!v || "Your number is required.",
          (v) => v.length <= 10 || "Number must be valid.",
        ],
        age: [
          (v) => !!v || "Your age is required.",
          (v) => v.length <= 2 || "Age must be valid.",
        ],
        destination: [(v) => !!v || "Your destination is required."],
        message: [(v) => !!v || "Your message is required."],
        courses: [(v) => !!v || "Your courses is required."],
        plan: [(v) => !!v || "Your plan is required."],
      },
    };
  },
  watch: {
    selectedCategories(value) {
      const cources = this.categories.find((cources) => cources.name === value);
      this.courses = cources ? cources.courses : [];
      this.selectedCourses = null;
    },
  },

  methods: {
    isValidEmail(v) {
      return String(v)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    specialCharacter(v) {
      return String(v).match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);
    },
    specialNumber(v) {
      return String(v).match(/\d/);
    },
    sendEmail(e) {
      if (this.$refs.submitForm.validate()) {
        const SERVICE_ID = process.env.VUE_APP_SERVICE_ID;
        const TEMPLATE_ID = process.env.VUE_APP_TEMPLATE_ID;
        const USER_ID = process.env.VUE_APP_USER_ID;
        try {
          emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID);
          this.isMailSent = true;
        } catch (error) {}
        this.$refs.submitForm.reset();
      }
    },
  },
};
</script>

<style scoped>
.our-form {
}
.form-text {
  color: #000;
  font-family: "Lexend", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.form-validation {
  width: 100%;
}
.select-box {
  border: 1px solid rgba(0, 0, 0, 0.38);
  width: 100%;
  min-height: 56px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.select-box:hover {
  border: 1px solid #000;
}
</style>

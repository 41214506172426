<template>
  <div id="footer">
    <v-container class="justify-center d-flex mb-16">
      <v-row
        class="justify-center d-flex align-center number-count-container"
        data-aos="fade-up" data-aos-duration="1500"
      >
        <v-col cols="12" class="text-center pb-0">
          <span
            class="section-title total-passed"
            style="color: #fff !important"
          >
            Why choose us?</span
          >
        </v-col>
        <v-row class="justify-center" ref="numberSection">
          <v-col
            cols="12 pa-0"
            xs="12"
            sm="3"
            md="3"
            lg="3"
            xl="2"
            v-for="(number, index) in numbers"
            :key="index"
          >
            <div
              class="number-count-card d-flex flex-column justify-center align-center"
            >
              <div>
                <number
                  ref="number"
                  :from="0"
                  :to="number.count"
                  :duration="2.5"
                  class="total-passed transition mr-n2 ml-2"
                  easing="Power1.easeOut"
                  animationPaused
                />
                <span>{{ number.unit }}</span>
              </div>
              <span
                class="section-para"
                style="color: #fff !important; font-size: 13px"
                >{{ number.successName }}</span
              >
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-row class="ma-0 pt-10 footer-second-section text-center text-lg-left">
      <v-container class="d-flex pa-0 flex-column flex-lg-row">
        <v-row class="ma-0">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            class="justify-center align-center align-lg-start d-flex flex-column px-0"
            style="gap: 25px"
          >
            <span class="footer-title">Location</span>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3541.283476290218!2d85.031453!3d27.4292749!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb49c88e198ffd%3A0xd668d31995341953!2sCentrum%20Academy!5e0!3m2!1sen!2snp!4v1679726913242!5m2!1sen!2snp"
                width="300"
                height="250"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >
            <span class="footer-title">Destination</span>
            <v-row
              class="ma-0 mt-4 justify-center flex-column"
              style="gap: 15px"
            >
              <span
                class="footer-list-item lexend"
                v-for="(destination, index) in destinations"
                :key="index"
                >
                <a style="color: #fff;" @click="navigateToDestination(destination.slug)">{{ destination.country }}</a>
                </span
              >
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="mobile-footer-contact-us">
            <span class="footer-title">Contact Us</span>
            <v-row
              class="ma-0 mt-4 justify-center flex-column"
              style="gap: 2px"
            >
              <div class="navigation-phone">
                <v-icon size="20" color="white">mdi-phone</v-icon>
                <span class="lexend footer-list-item">
                  &nbsp;&nbsp;057 524289</span
                >
              </div>
              <div class="navigation-phone">
                <v-icon size="20" color="white">mdi-cellphone</v-icon>
                <span class="lexend footer-list-item">
                  &nbsp;&nbsp;+977 9855031989</span
                >
              </div>
              <div class="mt-9">
                <span class="footer-title">Email Us</span>
                <div class="navigation-phone mt-4" style="white-space:nowrap;">
                  <v-icon size="20" color="white">mdi-email</v-icon>
                  <span class="lexend footer-list-item">
                    &nbsp;&nbsp;info.centrumacadamy@gmail.com</span
                  >
                </div>
              </div>
              <div class="navigation-phone mt-5">
                <v-icon size="20" color="white">mdi-map-marker-radius</v-icon>
                <span class="lexend footer-list-item">
                  &nbsp;&nbsp;Hetauda-4, School Road</span
                >
              </div>
              <div class="navigation-phone">
                <v-icon size="20" color="white" class="non-visible"
                  >mdi-map-marker-radius</v-icon
                >
                <span class="lexend footer-list-item">
                  &nbsp;&nbsp;Rijal Plaza 1st floor</span
                >
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-row
        class="ma-0 mt-10 footer-consultancy-info align-center justify-center"
        style="gap: 13px"
      >
      </v-row>
      <v-container class="text-center pb-2 px-0">
        <v-divider class="divider"></v-divider>
        <span class="reserved-text lexend"
          >© {{ year }} Centrum Academy Consultancy. All rights reserved.
        </span>
      </v-container>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isInView: false,
      numbers: [
        {
          count: new Date().getFullYear() - 2018,
          successName: "Year Experience",
          unit: "+",
        },
        {
          count: "100",
          unit: "%",
          successName: "Total passed",
        },
        {
          count: "1000",
          unit: "+",
          successName: "Visa Approved",
        },
      ],
      destinations:[
        {
          slug: 'study-in-usa',
          country: 'Study in USA'
        },
        {
          slug: 'study-in-australia',
          country: 'Study in Australia'
        },
        {
          slug: 'study-in-canada',
          country: 'Study in Canada'
        },
        {
          slug: 'study-in-japan',
          country: 'Study in Japan'
        },
        {
          slug: 'study-in-uk',
          country: 'Study in UK'
        },
        {
          slug: 'study-in-germany',
          country: 'Study in Germany'
        }
      ],
      contacts: [
        {
          info: "9818306105 ",
        },
        {
          info: "015910770",
        },
        {
          info: "Muskan daie",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.checkForNumberAnimation);
    this.checkForNumberAnimation();
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkForNumberAnimation);
  },
  methods: {
    navigateToDestination(slug) {
      if(slug === 'study-in-usa' ) {
        this.$router.push('/destinations/study-in-usa/')
      }
      if(slug === 'study-in-canada' ) {
        this.$router.push('/destinations/study-in-canada')
      }
      if(slug === 'study-in-australia' ) {
        this.$router.push('/destinations/study-in-australia')
      }
      if(slug === 'study-in-japan' ) {
        this.$router.push('/destinations/study-in-japan')
      }
      if(slug === 'study-in-uk' ) {
        this.$router.push('/destinations/study-in-uk')
      }
      if(slug === 'study-in-germany' ) {
        this.$router.push('/destinations/study-in-germany')
      }
    },
    checkForNumberAnimation() {
      if (!this.$refs.numberSection) return;
      const rect = this.$refs['numberSection'].getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      this.isInView = rect.top <= windowHeight && rect.bottom >= 0;
      if (this.isInView) {
        this.startNumberAnimation();
      }
    },
    startNumberAnimation() {
        this.$refs.number[0].play()
        this.$refs.number[1].play()
        this.$refs.number[2].play()
    },
  },
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style scoped>
#footer {
  position: relative;
  background: #a1315d;
  /* height:200px; */
}

.number-count-container {
  position: absolute;
  width: 80%;
  top: -60px;
  background: #411854 !important;
  border-radius: 20px;
}
.footer-second-section {
  width: 80%;
  margin: auto !important;
}

.number-count-card {
  font-family: "Lexend", sans-serif !important;
  color: #fff !important;
  max-height: 150px;
  font-weight: bold;
  font-size: 30px;
  border-radius: 20px;
  height: 160px;
}

.transition {
  transition: all 0.3s ease-in;
}

.footer-consultancy-info {
  background: #a1315d;
  height: 100px;
}
.footer-list-item {
  font-size: 16px !important;
  color: #fff !important;
}
.reserved-text {
  font-size: 13px !important;
  color: #fff !important;
}
.divider {
  background: #fff;
  margin-bottom: 8px;
}
</style>

<template>
  <div>
    <div class="study-section d-flex align-center">
      <v-container>
        <v-row class="ma-0">
          <v-row
            class="home-content py-16 align-center text-center text-lg-left"
          >
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <div class="section-title lexend mb-7">
                <span>Study in Canada</span>
              </div>
              <p class="lexend-white">
                Pursue your higher studies in the Canada with world's most
                prestigious colleges and universities.
              </p>
              <v-btn class="section-btn mt-3" @click="goToForm"
                >Inquiry Now</v-btn
              >
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-row class="ma-0 study-second-section">
        <v-container class="py-12">
          <v-row class="d-flex ma-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="8"
              xl="6"
              class="d-flex text-justify"
            >
              <span class="study-para">
                Canada is a North American country located to the north of the
                United States. It is the second-largest country in the world by
                land area, covering approximately 9.98 million square
                kilometers. Canada is a multicultural and bilingual country with
                two official languages, English and French. It has a diverse
                population of over 38 million people and is known for its
                friendly people, high quality of life, and stunning natural
                landscapes. Canada has a highly developed economy and is one of
                the wealthiest countries in the world. Its economy is based on a
                mix of industries, including natural resources, manufacturing,
                and services. It is a member of the G7 and G20, and is also a
                member of the United Nations, NATO, and the Commonwealth of
                Nations. Canada is known for its natural beauty and diverse
                geography, including mountains, forests, lakes, and oceans. It
                is home to several iconic landmarks such as the Niagara Falls,
                the Rocky Mountains, and the CN Tower. It is also known for its
                national parks, including Banff, Jasper, and Gros Morne.
              </span>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="4"
              xl="6"
              class="text-center"
            >
              <v-img
              class="ma-auto"
                src="../../assets/images/destination/canada.jpg"
                height="300"
                max-width="450"
              ></v-img>
              <br />
              <span class="city-name">Canada, City Skyline</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-container class="py-12 px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">Why study in Canada? </span>
                <span class="study-para">
                  There are several reasons why international students choose to
                  study in the Germany:
                  <dl>
                    <dt>Quality Education</dt>
                    <dd>
                      Canadian universities and colleges are known for their
                      high academic standards and cutting-edge research
                      facilities, providing students with a world-class
                      education.
                    </dd>
                    <dt>Multicultural Environment</dt>
                    <dd>
                      Canada is a diverse and multicultural country, welcoming
                      students from all over the world and providing a safe and
                      inclusive environment for all.
                    </dd>
                    <dt>Work Opportunities</dt>
                    <dd>
                      Canada provides international students with excellent work
                      opportunities while they study, enabling them to gain
                      valuable work experience and supplement their income.
                    </dd>
                    <dt>Affordable Education</dt>
                    <dd>
                      Canadian universities and colleges offer competitive
                      tuition fees compared to other countries, making it an
                      affordable option for international students.
                    </dd>
                    <dt>High Standard of Living</dt>
                    <dd>
                      Canada is consistently ranked as one of the best places to
                      live in the world, providing students with a high standard
                      of living and quality of life.
                    </dd>
                  </dl>
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title">
                  Choosing a Course and University
                </span>
                <span class="study-para">
                  Canada offers a wide range of courses and universities for
                  international students. To select the best course and
                  university for you, it is essential to consider several
                  factors, such as your interests, career goals, and budget. You
                  can research courses and universities online, read student
                  reviews, and speak to education agents for advice. When
                  selecting a university, it is crucial to consider the
                  university's reputation, course quality, and teaching methods.
                  Canadian universities use a variety of teaching methods, such
                  as lectures, seminars, and practical sessions, to provide
                  students with a well-rounded education.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
          <v-row class="ma-0">
            <v-container class="px-0">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> The Application Process </span>
                <span class="study-para">
                  To apply for a course in Canada, you will need to submit an
                  application directly to the university or college. You will
                  need to provide academic transcripts, language test results,
                  and any other documents required by the institution. Once your
                  application is accepted, you will receive a letter of
                  acceptance. You will need to obtain a study permit before you
                  can study in Canada.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>

          <v-row class="ma-0">
            <v-container class="px-0 mb-12">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="21"
                xl="12"
                class="d-flex flex-column"
              >
                <span class="section-title"> Student Life in the Canada </span>
                <span class="study-para">
                  Studying in Canada offers a unique student experience.
                  Canadian universities and colleges provide excellent support
                  services, such as accommodation, career advice, and health
                  services. Students can also participate in extracurricular
                  activities, such as sports, clubs, and societies, to make new
                  friends and explore their interests. Canada has a range of
                  outdoor activities and cultural experiences to offer students,
                  such as skiing, hiking, visiting historical landmarks, and
                  experiencing traditional Canadian food and culture.
                </span>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> </v-col>
            </v-container>
          </v-row>
        </v-container>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
    methods: {
    goToForm() {
        this.$router.push('/contact-us')
  }
  }
};
</script>

<style scoped>
.study-section {
  min-height: 500px;
  background: url(../../assets/images/country/canada-background.jpg)
    center/cover no-repeat;
}
.study-second-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/aeroplane.gif);
}
</style>

<template>
  <div class="our-destination">
    <v-container>
      <v-row class="py-16">
        <v-col
          cols="12"
          class="ma-auto justify-center d-flex flex-column text-center align-center"
        >
          <span class="lexend content-title" data-aos="fade-up" data-aos-duration="1000">Our Destinations</span>
          <div class="section-title text-center mb-7" data-aos="fade-up" data-aos-duration="1300">
            <span>Discover your path and destinations.</span>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="d-flex justify-center flex-column flex-lg-row"
          style="gap: 30px"
        >
          <div
          data-aos="fade-up" data-aos-duration="1600"
            class="destination-img align-center d-flex justify-center"
            v-for="(country, i) in countries"
            :key="i"
          >
            <img :src="country.image" height="204" width="204" data-aos="fade-up" data-aos-duration="1600"/>
            <v-btn class="destination-btn" @click="navigateToStudy(i)" data-aos="fade-up" data-aos-duration="2000">
              {{ country.name }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: [
        {
          name: "Australia",
          image: [require("../assets/images/destination/sydney.jpg")],
        },
        {
          name: "Uk",
          image: [require("../assets/images/destination/uk.jpg")],
        },
        {
          name: "Usa",
          image: [require("../assets/images/destination/usa.jpg")],
        },
        {
          name: "canada",
          image: [require("../assets/images/destination/canada.jpg")],
        },
        {
          name: "Japan",
          image: [require("../assets/images/destination/dubai.jpg")],
        },
      ],
    };
  },
  methods: {
    navigateToStudy(i) {
          if(i === 0 ) {
        this.$router.push('/destinations/study-in-australia')
          }
          if(i === 1 ) {
        this.$router.push('/destinations/study-in-uk')
          }
          if(i === 2 ) {
        this.$router.push('/destinations/study-in-usa')
          }
          if(i === 3 ) {
        this.$router.push('/destinations/study-in-canada')
          }
          if(i === 4 ) {
        this.$router.push('/destinations/study-in-japan')
          }
        }
      }
};
</script>

<style scoped>
.our-destination {
  background: #fff;
  position: relative;
  /* background-position: left top;
    background-repeat: no-repeat;
    background-image: url(../assets/images/aeroplane.gif); */
}
.destination-img img {
  border-radius: 50%;
  transition: transform .5s;
}
.destination-img img:hover {
    transform: translateY(-4px);
}
.destination-img img:hover .destination-btn {
    transform: translateY(-4px) !important;
}
.destination-img {
  position: relative;
}
.destination-btn {
  position: absolute;
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 8px 15px !important;
  color: #fff !important;
  border-radius: 30px;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-family: Montserrat !important;
  height: 40px !important;
  min-width: 100px !important;
  transition: background 0.3s;
}
.destination-btn:hover {
  background: #520e1b !important;
}
</style>
